import "lite-youtube-embed";

/* Cross-browser support for ensuring functions fire when DOM is loaded */
if (document.readyState !== "loading") {
  initVideo();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    initVideo();
  });
}

function initVideo() {
  const prefersReducedMotion = window.matchMedia(
    "(prefers-reduced-motion: reduce)"
  ).matches;

  // If not mobile, or user doesn't prefer reduced motion, continue.
  if (!window.matchMedia("(min-width: 768px)").matches || prefersReducedMotion)
    return;

  const player = document.querySelector(".hero lite-youtube");

  // Remove tabindex for added play btn
  // TODO: Add button as actual play/pause btn
  const playBtn = document.querySelector(".lty-playbtn");
  playBtn.setAttribute("tabindex", "-1");

  // Check if player exists
  if (!player) return;
  player.parentElement.removeAttribute("hidden");
  player.querySelector("button").click(); // Manually trigger play
  const playerIframe = player.querySelector("iframe");
  playerIframe.style.width = 1024 + "px";
  playerIframe.style.height = 568 + "px";

  // Set up observer to ensure video always covers parent height
  const videoObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      entry.target.style.width = entry.contentRect.height * (16 / 9) + "px";
    });
  });

  // Pass iframe through to observer
  videoObserver.observe(playerIframe);
}
