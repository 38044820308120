// import "@lion/tabs/define";
import { css } from "@lion/core";
import { LionTabs } from "@lion/tabs";

class Tabs extends LionTabs {
  static get styles() {
    return [
      ...super.styles,
      css`
        .tabs__tab-group {
          padding: var(--size-100);
          border-radius: var(--border-radius);
          background: var(--color-grey-200);
          overflow: auto hidden;
          overscroll-behavior: contain;
          scroll-snap-type: x mandatory;
          scroll-behavior: smooth;
        }

        @media (hover: none) {
          .tabs__tab-group {
            scrollbar-width: none;
          }

          .tabs__tab-group::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        }
      `,
    ];
  }
}
customElements.define("etc-tabs", Tabs);
